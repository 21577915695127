export let permlevel = 0;
export let eauid = 0;

export function getCookie(name) {
    const arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
    // return arr
    if (arr != null) {
        return unescape(arr[2])
    } else {
        permlevel = 0;
        return null
    }
  }

export function is_login() {
    let cookies = getCookie('session_id')
    if (cookies == null) {
        permlevel = 0;
    }
    return cookies != null
}

export function set_permlevel(new_value) {
    permlevel = new_value;
}

export function get_permlevel() {
    return permlevel;
}

export function set_eauid(new_value) {
    eauid = new_value;
}

export function get_eauid() {
    return eauid;
}

export default {
    is_login,
    getCookie,
    set_permlevel,
    get_permlevel,
    set_eauid,
    get_eauid
}