<template>
    <div>
        <h4>站内信 (BETA)</h4>
        <hr>
        <b-row align-h="between">
            <b-col cols="auto" size="sm">
                <b-form-group class="form-inline">
                    <b-form-radio-group
                        v-if="screenWidth > 576"
                        id="btn-radios-2"
                        v-model="msg_type"
                        :options="[
                                { text:'全部消息', value: 'all' },
                                { text:'已读消息', value: 'read' },
                                { text:'未读消息', value: 'unread' },
                            ]"
                        button-variant="outline-primary"
                        size="sm"
                        name="radio-btn-outline"
                        buttons
                        @change="$refs.table.refresh()"
                        ref="selection"
                    />
                    <b-select v-model="msg_type" v-else
                              :options="[
                                { text:'全部消息', value: 'all' },
                                { text:'已读消息', value: 'read' },
                                { text:'未读消息', value: 'unread' },
                            ]"
                              @change="$refs.table.refresh()"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="auto" size="sm" >
                <b-button size="sm" v-on:click="read_all()" v-if="msg_type !== 'read'">全部已读</b-button>
                <b-button size="sm" v-on:click="r()">刷新</b-button>
            </b-col>
        </b-row>


        <b-table ref="table" striped hover :items="myProvider" :fields="fields" :per-page="25" :current-page="currentPage" stacked="true">
            <template v-slot:cell(created_At)="data">
                <p> {{ new Date(data.item['created_At']).toLocaleDateString() }}</p>
            </template>
            <template v-slot:cell(msg_id)="data">
                <b class="btn-link mr-1" v-on:click="show(data.item)">阅读消息</b>
                <b class="btn-link mr-1" @click="current=data.item; read_complete()" v-if="!data.item.status">设为已读</b>
                <b-icon icon="check-circle" v-else></b-icon>
            </template>
        </b-table>
        <b-modal id="message_box" :title="current.title" hide-footer>
            <div v-html="current.content"></div>
            <b-row>
                <b-col v-if="current.extra && current.extra['case_id'] && current.extra['reply_id']">
                    <b-button size="sm" :to="'/case/' + current.extra['case_id'] + '/' + current.extra['reply_id']" block @click="$bvModal.hide('message_box')">前往该回复</b-button>
                </b-col>
                <b-col v-else-if="current.extra && current.extra['case_id']">
                    <b-button size="sm" :to="'/case/' + current.extra['case_id']" block @click="$bvModal.hide('message_box')">前往该案件</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import {request} from "@/api";
export default {
    name: "UserNotify",
    data: function() {
        return {
            msg_type: "unread",
            fields: [
                {key: "created_At", label: "时间"},
                {key: "title", label: "标题"},
                {key: "msg_id", label: "操作"}
            ],
            currentPage: 1,
            screenWidth: 0,
            current: {
                "title": "",
                "content": "",
                "msg_id": -1,
                "status": false
            }
        }
    },
    methods: {
        myProvider(ctx, callback){
            // https://api.bfeac.com/user/self/message/list?msg_type=all&pn=1&size=25
            request('GET', '/user/self/message/list?msg_type=' + this.msg_type + '&pn=' + ctx.currentPage + '&size=' + ctx.perPage)
                .then(data => {
                    callback(data)
                })
                .catch(() => {
                    callback([])
                })
            return null
        },
        show(current_) {
            this.current = current_
            this.$bvModal.show('message_box')
            this.read_complete()
        },
        read_complete() {
            if (!this.current.status) {
                this.current.status = true
                request('GET', '/user/self/message/set_read?msg_id=' + this.current.msg_id)
            }
        },
        read_all() {
            request('GET', '/user/self/message/set_read_all')
            this.$refs.table.refresh()
        },
        r() {
            this.$refs.table.refresh()
        }
    },
    mounted () {
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {   //屏幕尺寸变化就重新赋值
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    }
}
</script>

<style scoped>

</style>