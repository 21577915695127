<template>
    <div id="reportPage">
        <b-card title="举报页面">
            <b-form-group label="举报ID" class="form-inline">
                <b-form-input v-model="report_EAID" />
            </b-form-group>
            <b-form-group label="游戏" class="form-inline">
                <b-form-radio-group
                    id="game"
                    v-model="game_selected"
                    name="radio-sub-component"
                >
                    <b-form-radio value="1">战地1</b-form-radio>
                    <b-form-radio value="5" class="disabled">战地5 (人手不足, 开放会通知)</b-form-radio>
                    <b-form-radio value="0">其他</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
            <b-form-group label="理由">
                <div class="mt-3"><MarkedDownInputBox ref="report_reason" toolbars="toolbars"/></div>
            </b-form-group>
            <b-card-footer>
                <b-button variant="outline-primary float-right" v-on:click="submit_report()" class="pull-right">提交</b-button>
            </b-card-footer>
        </b-card>
    </div>
</template>

<script>
import { get_personaId, request, upload_image } from "@/api";
import MarkedDownInputBox from "@/components/MarkedDownInputBox";

export default {
    name: "SubmitReport",
    data() {
        return {
            report_EAID: null,
            report: null,
            game_selected: 1,
        }
    },
    methods: {
        async submit_report () {
            try {
                this.$refs.report_reason.waiting = true  // 显示转圈等待的效果
                const confirm = await this.$bvModal.msgBoxConfirm("确定要提交本次举报吗?")
                if (!confirm) {
                    return
                }
                if (this.$refs.report_reason.markdown_value.length < 15) {
                    await this.$bvModal.msgBoxOk("举报字数请大于15字!", {title: '失败!'})
                    return
                }
                const target_details = await get_personaId(this.report_EAID)
                await request('POST', '/case/new', {
                    target_user_Id: target_details.userId, target_personaId: target_details.personaId,
                    report_markdown: this.$refs.report_reason.markdown_value, game: this.game_selected
                })
                await this.$bvModal.msgBoxOk("举报已经提交!", {title: '成功!'})
                this.$emit("submit_successful")
            } catch (e) {
                await this.$bvModal.msgBoxOk(e, {title: '警告!'})
            } finally {
                this.$refs.report_reason.waiting = false  // 结束显示
            }
        },
        imgAdd (pos, $file) {
            upload_image($file)
                .then(res => {
                    this.$refs.report_box.$img2Url(pos, res)
                })
                .catch(e => {
                    this.$bvModal.msgBoxOk(e, {title: '警告!'})
                })
        }
    },
    components: {
        MarkedDownInputBox
    },
}
</script>