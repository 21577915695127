// 调用API

export const ApiBaseUrl = "https://api.bfeac.com"
export const CookiesPolicy = 'include'


export async function request(request_method = 'GET', route = '', data=null){
    let post_body = null;
    if (data != null) {
        post_body = JSON.stringify(data)
    }

    const response = await fetch(ApiBaseUrl + route, {
        method: request_method,
        body: post_body,
        headers: {
            'content-type': 'application/json'
        },
        credentials: CookiesPolicy
    });
    const response_json = await response.json();
    // 针对返回值进行判断.

    if (response_json['error_code'] !== 0) {  // error_msg
        throw [response_json['error_code'], response_json['error_msg']]
    }
    return response_json['data']
}


export async function get_personaId(EAID) {
    const response = await fetch("https://api.k-0n.org/origin/getPid/" + EAID)
    const response_json = await response.json()
    if (response_json['Status'] === false) {
        throw response_json['Data']['Error']
    }
    return {
        userId: response_json['Data']['UserId'],
        personaId: response_json['Data']['PersonaId'],
        EAID: response_json['Data']['EAID']
    }
}


export async function upload_image($file) {
    let form_data = new FormData()
    form_data.append('file', $file)
    const response = await fetch(ApiBaseUrl + "/utils/upload_image", {
        method: "POST",
        body: form_data,
        credentials: CookiesPolicy
    })
    // {"error_code":0,"error_msg":null,"data":"https://vip2.loli.io/2022/05/11/6wEWa7AjgiTNnPV.jpg"}
    const response_json = await response.json()
    if (response_json["error_code"] !== 0){
        throw response_json["error_msg"]
    }
    return response_json['data']
}