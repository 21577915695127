<template>
    <b-container>
        <div>
            <b-card class="shadow" id="fadein">
                    <router-view />
            </b-card>
        </div>


<!--
        <b-row>
            <b-col cols="2">
                <b-list-group class="shadow" id="fadein">
                    <b-list-group-item button class="text-center">菜单</b-list-group-item>
                    <b-list-group-item href="#/user/notify">通知</b-list-group-item>
                </b-list-group>
            </b-col>
            <b-col cols="10">
                <b-card class="shadow" id="fadein">
                    <router-view />
                </b-card>
            </b-col>
        </b-row>
-->


    </b-container>
</template>

<script>
export default {
    name: "PageUser"
}
</script>

<style scoped>

</style>