<template>
    <div class="container">
        <b-row align-h="center">
            <b-col cols="8" >
                <b-card class="shadow" id="fadein">
                    <b-overlay :show="pending" rounded="sm">
                        <b-form>
                            <b-form-group
                                id="input-group-1"
                                label="注册时邮箱地址:"
                                label-for="input-1"
                            >
                                <b-form-input
                                    id="input-1"
                                    v-model="form.email"
                                    type="email"
                                    placeholder="邮箱地址 (不会公开)"
                                    required
                                ></b-form-input>
                            </b-form-group>
                            <vue-recaptcha sitekey="6LeXOQ8hAAAAAOs5mlB3r72NEaPqTQk17FsqYFv4"
                                           :loadRecaptchaScript="true"
                                           recaptchaHost="www.recaptcha.net"
                                           ref="recaptcha_register"
                                           @verify="verify_google_feedback" 
                                           id="resized"/>
                            <br><b-button variant="primary" v-on:click="do_register">完成验证!</b-button>
                        </b-form>
                    </b-overlay>

                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import {request} from '@/api'

export default {
    name: "PageRegister_Validation",
    components: { VueRecaptcha },
    data() {
        return {
            form: {
                email: '',
                google_code: ''
            },
            show: true,
            code: '',
            pending: false
        }
    },
    methods: {
        async do_register() {
            if (this.form.google_code === '' || this.form.google_code === null) {
                await this.$bvModal.msgBoxOk("未完成谷歌验证!", {title: '警告!'})
                return
            }
            this.pending = true
            request('POST', '/user/email_validate', {
                email: this.form.email,
                code: this.code,
                captcha: this.form.google_code})
                .then(() => {
                    this.$bvModal.msgBoxOk("验证成功!")
                })
                .catch(e => {
                    this.$bvModal.msgBoxOk(e[1])
                })
                .finally(() => {
                    this.pending = false
                    this.$refs.recaptcha_register.reset()
                })
        },
        verify_google_feedback(code) {
            this.form.google_code = code
        }
    },
    created() {
        this.code = this.$route.params.token
    },
}
</script>

<style scoped>

</style>