<template>
    <div class="container-fluid">
        <b-row align-h="center">
            <b-col cols="8" >
                <b-card class="shadow" id="fadein" style="min-width:270px">
                    <b-form>
                        <b-form-row>
                            <p>注册指引：<a href="https://www.bilibili.com/read/cv22852534">https://www.bilibili.com/read/cv22852534</a></p>
                        </b-form-row>
                        <b-form-group
                            id="input-group-1"
                            label="邮箱地址:"
                            label-for="input-1"
                        >
                            <b-form-input
                                id="input-1"
                                v-model="form.email"
                                type="email"
                                placeholder="邮箱地址 (不会公开)"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="游戏ID" label-for="input-2">
                            <b-form-input
                                id="input-2"
                                v-model="form.eaid"
                                placeholder="OriginId"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-3" label="昵称" label-for="input-3">
                            <b-form-input
                                id="input-3"
                                v-model="form.nickname"
                                placeholder="显示在前台, 举报页面"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-4" label="密码" label-for="input-4">
                            <b-form-input
                                type="password"
                                id="input-4"
                                v-model="form.password"
                                required
                            ></b-form-input>
                            <b-form-text id="password-help-block">
                                设定的密码至少长8位,至多20位, 需要包括字母与数字, 不推荐与其他网站共用同一个密码.
                            </b-form-text>
                        </b-form-group>

                        <b-form-group id="input-group-4" label="重复密码" label-for="input-5">
                            <b-form-input
                                type="password"
                                id="input-4"
                                v-model="form.password_2"
                                required
                            ></b-form-input>
                            <b-form-text id="password-help-block">
                                需要和设定的密码一致
                            </b-form-text>
                        </b-form-group>
                        <vue-recaptcha sitekey="6LeXOQ8hAAAAAOs5mlB3r72NEaPqTQk17FsqYFv4"
                                       :loadRecaptchaScript="true"
                                       recaptchaHost="www.recaptcha.net"
                                       ref="recaptcha_register"
                                       @verify="verify_google_feedback" 
                                       id="resized"/>
                        <br><b-button variant="primary" v-on:click="do_register">注册!</b-button>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import {request} from '@/api'

export default {
    name: "PageRegister",
    components: { VueRecaptcha },
    data() {
        return {
            form: {
                email: '',
                eaid: '',
                nickname: '',
                password: '',
                password_2: '',
                google_code: ''
            },
            show: true
        }
    },
    methods: {
        async do_register() {
            if (this.form.google_code === '' || this.form.google_code === null) {
                await this.$bvModal.msgBoxOk("未完成谷歌验证!", {title: '警告!'})
                return
            }
            // 进行注册操作.
            if (this.form.password !== this.form.password_2) {
                await this.$bvModal.msgBoxOk("两次密码必须一致!", {title: '警告!'})
                return
            }
            request('POST', '/user/register', {
                email: this.form.email,
                nickname: this.form.nickname,
                EAID: this.form.eaid,
                password: this.form.password,
                captcha: this.form.google_code})
                .then(() => {
                    this.$bvModal.msgBoxOk("注册成功! 请前往邮箱中接受验证邮件, 验证完成后方可登录!")
                })
                .catch(e => {
                    this.$bvModal.msgBoxOk(e[1])
                    this.$refs.recaptcha_register.reset()
                })
        },
        verify_google_feedback(code) {
            this.form.google_code = code
        }
    }
}
</script>

<style scoped>

</style>