<template>
    <div class="container">
        <b-row align-h="center">
            <b-card>
                <b-card-title>注册教程</b-card-title>
                <b-card-body>
                    <p>1. 请在网页版(origin.com) 或origin客户端中登陆您的EA帐号.</p>
                </b-card-body>
                <b-card-body>
                    <p>2. 点击左下角的用户名, 在弹出的菜单中点击 “EA帐号与服务”</p>
                    <b-img-lazy src="https://vip2.loli.io/2022/08/28/dhiatnDZQrq5Mf1.png" />
                </b-card-body>
                <b-card-body>
                    <p>3. 进入新的页面后, 在左侧菜单中点击 "隐私设定"</p>
                    <b-img-lazy src="https://vip2.loli.io/2022/08/28/JnUaVPsqFvD9y2p.png" />
                </b-card-body>
                <b-card-body>
                    <p>4. 在页面中勾选 "电子邮件地址", 在完成相关验证后, 您可以再次关闭该选项.</p>
                    <b-img-lazy src="https://vip2.loli.io/2022/08/28/QfpbiDWlBhVevNo.png" />
                </b-card-body>
                <b-card-body>
                    <p>5. 正常注册即可.</p>
                </b-card-body>
            </b-card>
        </b-row>

    </div>
</template>

<script>
export default {
    name: "PageRegisterGuide"
}
</script>

<style scoped>

</style>