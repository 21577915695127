<template>
    <b-container>
        <h4>操作日志</h4>
        <hr>
        <b-table ref="table" striped hover :items="myProvider" :fields="fields" :per-page="pg_size" :current-page="currentPage" stacked="true" responsive="sm">
            <template #cell(show_details)="row">
                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                    {{ row.detailsShowing ? '隐藏' : '详情'}}
                </b-button>
            </template>
            <template #row-details="row">
                <b-card>
                    <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"><b>操作IP:</b></b-col>
                        <b-col>{{ row.item["operator_ip"] }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"><b>操作详情:</b></b-col>
                        <b-col>{{ row.item["operate_content"] }}</b-col>
                    </b-row>

                    <b-row class="mb-2" v-if="row.item['be_operated_object'] != null">
                        <b-col sm="3" class="text-sm-right"><b>被操作对象:</b></b-col>
                        <b-col>{{ row.item["be_operated_object"] }}</b-col>
                    </b-row>

                    <b-button size="sm" @click="row.toggleDetails" variant="outline-primary float-right">隐藏</b-button>
                </b-card>
            </template>
        </b-table>
        <div class="mx-auto">
            <b-pagination v-if="pg_size <= total_size"
                          v-model="currentPage"
                          :total-rows="total_size"
                          :per-page="pg_size"
                          class="my-0 mx-auto"
                          right
            />
        </div>
    </b-container>
</template>

<script>
import {request} from "@/api";

export default {
    name: "SubUserLogs",
    data() {
        return {
            fields: [
                {key: "time", label: "操作时间"},
                {key: "operate_type", label: "操作类型"},
                {key: 'show_details', label: "详情"},
            ],
            pg_size: 10,
            currentPage: 1,
            total_size: 0
        }
    },
    methods: {
        myProvider(ctx, callback){
            request('GET', '/user-mgr/' + this.$route.params.id +'/logs?pn=' + ctx.currentPage + '&size=' + this.pg_size)
                .then(data => {
                    let result = []
                    for (let i in data["logs"]){
                        let tmp = data['logs'][i]
                        let date = new Date(tmp['time'])
                        tmp['time'] = date.toLocaleString()
                        result.push(tmp)
                    }
                    this.total_size = data['num']

                    callback(result)

                })
                .catch(() => {
                    callback([])
                    this.$bvModal.msgBoxOk("出错!")
                })
            return null
        },
    }
}
</script>

<style scoped>

</style>