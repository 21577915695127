<template>
    <div class="container">
        <div>
            
            <b-card 
            title="" sub-title="" class="shadow" id="fadein" style="background-color:whitesmoke"
            img-src="https://vip2.loli.io/2023/01/18/9GjeJNQDZXuroIc.jpg"
            img-alt="Image"
            img-top>
                
                <h1 class="display-5" ><i class="fa-solid fa-shield-halved"></i>&nbsp;战斗,心无旁骛</h1>
                <b-card-text>
                    作为玩家自发成立的组织，BFEAC致力于为战地带来良好的游戏环境。
                    <router-link to="/about" style="align-items: flex-end;"><i class="fa-solid fa-circle-info"></i>&nbsp;了解更多</router-link>
                </b-card-text>
                
            </b-card>
        </div>

        <br />
        
        <div>
            <b-card title="" sub-title="" class="shadow" id="fadein">
                
                
                <h1 class="display-5"><i class="fa-solid fa-bullhorn"></i>&nbsp;公示</h1>
                <b-card-text>
                    最近被BFEAC踢出的已封禁玩家
                </b-card-text>

                <PageKickedLog />
            </b-card>
        </div>
    </div>
</template>

<script>
import PageKickedLog from "@/components/PageKickedLog";

export default {
    components: {
      PageKickedLog
    }
}


</script>