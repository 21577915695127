<template>
    <div class="container">
        <PageAdmin />
    </div>

</template>

<script>
import PageAdmin from "@/components/PageAdmin";

export default {
  name: 'HelloWorld',
    data() {
        return {
            msg: "12345",
            show: true
        }
    },
    components: {
        PageAdmin
    },
    methods: {
    },
}
</script>