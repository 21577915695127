<template>
    <div class="container">
        <b-card class="shadow" id="fadein">
            <b-row>
                <b-col cols="3">
                    <b-list-group>
                        <b-list-group-item button class="text-center">菜单</b-list-group-item>
                        <b-list-group-item href="#/admin/user_list">用户管理</b-list-group-item>
                        <b-list-group-item href="#/admin/user_register">注册用户</b-list-group-item>
                    </b-list-group>
                </b-col>
                <b-col cols="9">
                    <b-card>
                        <router-view />
                    </b-card>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>


export default {
    name: "PageAdmin",
}
</script>

<style scoped>

</style>